import React, { useCallback, useState } from 'react';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { EFilterType, Filter, TFilterValue } from '@timeedit/ui-components';
import intl from 'i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import { activityFiltersSelector, changeFilterValue } from 'activities/pages/slices/activity.slice';
import { debounce } from 'lodash';

const language = intl.messages as Record<string, string>;

export default function ActivityFiltersStatusOnly() {
  const dispatch = useDispatch();
  const filterValues = useSelector(activityFiltersSelector);
  const [values, setValues] = useState(filterValues as Record<string, any>);

  const onFiltersChange = useCallback(
    debounce((updatedFilters: Record<string, TFilterValue>) => {
      dispatch(changeFilterValue(updatedFilters));
    }, 500),
    [],
  );

  return (
    <div>
      <Filter
        debounceTime={0}
        enableFreeSearch={false}
        defaultFields={[
          {
            key: 'status',
            label: language.status as string,
            multiselect: true,
            type: EFilterType.text,
          },
        ]}
        options={{
          status: [
            { label: language.created, value: EActivityStatus.CREATED },
            { label: language.in_review, value: EActivityStatus.IN_REVIEW },
            { label: language.constants_submitted as string, value: EActivityStatus.SUBMITTED },
          ],
        }}
        optionalFields={[]}
        settings={{
          filters: values,
          freeTextSearch: '',
        }}
        onChange={({ filters }) => {
          onFiltersChange(filters);
          setValues(filters);
        }}
      />
    </div>
  );
}
