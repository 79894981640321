import { IOption, TFilterValue } from '@timeedit/ui-components';
import dayjs, { Dayjs } from 'dayjs';

const localStoragePrefix = 'DM_';

class DMLocalStorage {
  instance: any;

  organizationId?: string;

  filters: {
    values: Record<string, TFilterValue>;
    period?: string[] | Dayjs[];
    options: Record<string, IOption[]>;
  };

  columnsWidth: Record<string, Record<string, number>>;

  columnsVisible: Record<string, string[]>;

  constructor() {
    this.instance = {};
    this.filters = {
      values: {},
      period: [dayjs().startOf('year').toJSON(), dayjs().endOf('year').toJSON()],
      options: {},
    };
    this.columnsWidth = {};
    this.columnsVisible = {};

    const self = this;
    window.addEventListener('beforeunload', () => {
      localStorage.setItem(`${localStoragePrefix}__${self.organizationId}__filters`, JSON.stringify(self.filters));
      localStorage.setItem(
        `${localStoragePrefix}__${self.organizationId}__columnsWidth`,
        JSON.stringify(self.columnsWidth),
      );
      localStorage.setItem(
        `${localStoragePrefix}__${self.organizationId}__columnsVisible`,
        JSON.stringify(self.columnsVisible),
      );
    });
  }

  init(organizationId: string) {
    this.organizationId = organizationId;
    try {
      this.filters =
        JSON.parse(localStorage.getItem(`${localStoragePrefix}__${this.organizationId}__filters`) as string) ||
        this.filters;
      this.columnsWidth =
        JSON.parse(localStorage.getItem(`${localStoragePrefix}__${this.organizationId}__columnsWidth`) as string) ||
        this.columnsWidth;
      this.columnsVisible =
        JSON.parse(localStorage.getItem(`${localStoragePrefix}__${this.organizationId}__columnsVisible`) as string) ||
        this.columnsVisible;
    } catch {
      console.log('Nothing stored in localStorage, use default settings');
    }
  }

  updateFilters(filters: DMLocalStorage['filters']) {
    this.filters = filters;
  }

  updateColumnsWidth(tableId: string, columnsWidth: Record<string, number>) {
    this.columnsWidth[tableId] = columnsWidth;
  }

  updateColumnsVisible(tableId: string, columnsVisible: string[]) {
    this.columnsVisible[tableId] = columnsVisible;
  }
}

export default new DMLocalStorage();
