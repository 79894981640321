import React from 'react';
import { TFormInstance } from '@timeedit/types/lib/types';
import { TActivityElementValue, TActivityRowRecord, TActivityStartEndDate } from 'activities/pages/types/activity.type';
import TEObjectManager from 'activities/services/TEObjects.service';
import intl from 'i18n/intl';
import { LockOutlined } from '@ant-design/icons';
import { toCenterOfWeek } from 'activities/utils';

const language = intl.messages;

export const toActivityValueDisplay = (activityValue: TActivityElementValue): string => {
  if (!activityValue) return '';
  if (Array.isArray(activityValue)) {
    return activityValue.map((val) => toActivityValueDisplay(val)).join(', ');
  }
  if (typeof activityValue === 'object') {
    return JSON.stringify(activityValue);
  }
  return TEObjectManager.getObjectLabel(activityValue);
};

export const toActivityReviewStatus = (formInstanceStatus: TFormInstance['status']) => {
  switch (formInstanceStatus) {
    case undefined:
      return {
        label: language.imported as string,
        color: 'processing',
        disabled: false,
      };

    case 'SUBMITTED':
      return {
        label: language.updated_in_preferences as string,
        color: 'geekblue',
        disabled: false,
      };

    default:
      return {
        label: language.reviewing_in_preferences as string,
        icon: <LockOutlined />,
        color: 'warning',
        disabled: true,
      };
  }
};
export const weekRangeChar = '-';
export const startAndEndToWeeks = (startEndDates: TActivityStartEndDate) => {
  const startWeek = toCenterOfWeek(startEndDates.startDate).isoWeek();
  const endWeek = toCenterOfWeek(startEndDates.endDate, 'end').isoWeek();
  return startWeek === endWeek ? startWeek.toString() : `${startWeek}${weekRangeChar}${endWeek}`;
};
