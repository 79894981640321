import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Select, Typography } from 'antd';
import intl, { getInlineString } from 'i18n/intl';
import {
  ActivityEvents,
  TActivityRawValue,
  TActivityResultsInResponseGroupByActivitySeries,
} from 'activities/pages/types/activity.type';
import ActivityValues from './ActivityValues';
import { useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';
import { getValueFromFormItem } from '../BaseElements/TEFormItem';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { addNewTrackToActivitySeries } from 'activities/services/activities.service';
import TEObjectsService from 'activities/services/TEObjects.service';
import ActivityWeeks from './ActivityWeeks';
import { PlusOutlined } from '@ant-design/icons';

const language = intl.messages as Record<string, string>;

type TActivityAddingNewTrackModalProps = {
  activitySeries: TActivityResultsInResponseGroupByActivitySeries;
  activityValues?: TActivity2Be['values'];
  initialValues: Record<string, TActivityRawValue>;
  onClose: () => void;
};

export default function ActivityAddingNewTrackModal({
  activitySeries,
  activityValues,
  initialValues,
  onClose,
}: TActivityAddingNewTrackModalProps) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const organizationId = useSelector(organizationIdSelector);

  const onSubmit = async () => {
    const values = await form.validateFields();
    if (!values.weeks?.length) return;

    if (organizationId && activitySeries?.activitySeriesId && activitySeries?.formId && activitySeries.sourceId) {
      setSaving(true);
      try {
        await addNewTrackToActivitySeries(
          {
            organizationId,
            activitySeries,
          },
          values,
        );
        document.dispatchEvent(
          new CustomEvent(ActivityEvents.CLOSE_ACTIVITY_SERIES_DRAWER, {
            detail: {
              activitySeriesId: activitySeries?.activitySeriesId,
              updated: true,
            },
          }),
        );
      } finally {
        setSaving(false);
      }
      onClose();
    }

    onClose();
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({ ...initialValues, numberOfTracks: 1 });
    }
  }, [open, initialValues]);

  return (
    <>
      <Form.Item label=" " colon={false}>
        <Button onClick={() => setOpen(true)} size="small" type="dashed">
          <PlusOutlined /> {language.add_tracks}
        </Button>
      </Form.Item>
      <Modal
        destroyOnClose
        title={getInlineString(
          'activities.add_new_tracks_modal.title',
          TEObjectsService.getObjectLabel(activitySeries.activityType),
        )}
        zIndex={100}
        open={open}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        modalRender={(modalContent) => (
          <Form labelCol={{ span: 8, offset: 0 }} labelAlign="left" form={form}>
            {modalContent}
          </Form>
        )}
        onOk={onSubmit}
        okButtonProps={{
          loading: saving,
        }}
        okText={language.create_tracks}
      >
        <>
          <div className="te-mb-2">
            <Typography.Text>{language['activities.add_new_tracks_modal.notes']}</Typography.Text>
          </div>
          <Form.Item label={language.number_of_tracks} name="numberOfTracks" tooltip={language.number_of_tracks}>
            <Select
              size="small"
              options={new Array(9).fill(null).map((item, itemIndex) => ({
                label: itemIndex + 1,
                value: itemIndex + 1,
              }))}
            />
          </Form.Item>
          <ActivityValues editable values={activityValues} multipleValuesExtIds={[]} timingDisabled>
            <ActivityWeeks allowMultiple editable={false} allowClear required />
          </ActivityValues>
        </>
      </Modal>
    </>
  );
}
