import React, { KeyboardEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { castArray, compact, flatten, groupBy, uniq } from 'lodash';
import { Button, Flex, notification } from 'antd';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import './WeekSelector.scss';
import { formatToCenterOfWeekDay, isInWeekRange, weekIsSelected } from './WeekSelector.utils';
import { toCenterOfWeek } from 'activities/utils';
import SelectedWeeksCounter from './SelectedWeeksCounter';
import intl from 'i18n/intl';

const language = intl.messages as Record<string, string>;

export type TWeekSelectorValue = number | number[];
export type TWeekSelectorBaseProps = {
  labelRender?: (week: number) => string; // Used to render custom week name
  allowMultiple?: boolean;
  disabledWeeks?: TWeekSelectorValue[];
  editable?: boolean;
  allWeeks?: TWeekSelectorValue[][]; // All selected weeks from all tracks, to calculate fulfilled weeks which has darken background
  numberOfTracks?: number; // All track of series, to calculate fulfilled weeks which has darken background
  required?: boolean;
  allowClear?: boolean;
  allowRange?: boolean;
  changeNote?: ReactNode | string; // Will be show after first change
  isBatchSelection?: boolean; // If true, all new selection will be applied for all tracks, have darken background
  name?: string;
};
type TWeekSelectorProps = TWeekSelectorBaseProps & {
  value: TWeekSelectorValue[];
  onChange: (value: TWeekSelectorValue[]) => void;
  allowRange?: boolean;
};

type TElementPosition = Record<'top' | 'left' | 'right' | 'bottom', number>;

export default function WeekSelector(props: TWeekSelectorProps) {
  const {
    value = [],
    onChange,
    allowMultiple,
    labelRender,
    allowRange = true,
    disabledWeeks,
    editable,
    allWeeks,
    numberOfTracks,
    changeNote,
    isBatchSelection,
  } = props;

  const [touched, setTouched] = useState(false);
  const tracker = useRef<number>(0);
  const currentHovering = useRef<null | HTMLButtonElement>(null);
  const isShifted = useRef<boolean>(false);
  const defaultYear = useMemo(() => {
    const firstSelectedWeek = castArray(value?.[0])[0];
    return dayjs(firstSelectedWeek).year();
  }, []);
  const [year, setYear] = useState(defaultYear);
  const [hovered, setHovered] = useState<number>(0);
  const [localFullFilledWeeks, setlocalFullFilledWeeks] = useState<string[]>([]);

  const formattedValue = useMemo(() => {
    return formatToCenterOfWeekDay(value);
  }, [value]);

  const formattedDisableWeeks = useMemo(() => {
    if (!disabledWeeks) return [];
    return formatToCenterOfWeekDay(disabledWeeks);
  }, [disabledWeeks]);

  const fullfilledWeeks = useMemo((): string[] => {
    if (!allWeeks || !numberOfTracks) return [];
    const formattedAllWeeks = allWeeks.map((weekOrWeekRange) => {
      const parsed = formatToCenterOfWeekDay(weekOrWeekRange);
      return parsed.map((item) => {
        if (Array.isArray(item)) return item.sort().join(',');
        return item.toString();
      });
    });
    const flattenAllWeek = flatten(formattedAllWeeks);
    const grouped = groupBy(flattenAllWeek);
    return Object.keys(grouped).filter((key) => grouped[key].length === numberOfTracks);
  }, [allWeeks, numberOfTracks]);

  const onSelect = (isShift: boolean, week: Dayjs) => {
    if (!editable) return;
    setTouched(true);
    isShifted.current = false;
    let updatedValues: TWeekSelectorValue[] = [];
    const weekValue = toCenterOfWeek(week).valueOf();
    let newSelected: TWeekSelectorValue = weekValue;
    const selectedValueIndex = formattedValue.findIndex((val) => {
      if (Array.isArray(val)) return isInWeekRange(weekValue, val);
      return val === weekValue;
    });
    if (selectedValueIndex > -1) {
      updatedValues = [...value.slice(0, selectedValueIndex), ...value.slice(selectedValueIndex + 1)];
      tracker.current = 0;
    } else if (isShift && tracker.current && allowRange) {
      const days = Math.abs(dayjs(weekValue).diff(tracker.current, 'days'));
      const diff = Math.ceil(days / 7);
      value.pop();
      formattedValue.pop();
      const newWeeks = new Array(1 + diff).fill(null).map((w, wIndex) => {
        return toCenterOfWeek(dayjs(Math.min(weekValue, tracker.current)).add(wIndex, 'week')).valueOf();
      });
      //  Check if there is any selected weeks in range
      const isOverlapped = newWeeks.some((weekOrWeekRange) => {
        if (Array.isArray(weekOrWeekRange)) {
          return weekOrWeekRange.some(
            (week) => weekIsSelected(week, formattedValue) || weekIsSelected(week, formattedDisableWeeks),
          );
        }
        return (
          weekIsSelected(weekOrWeekRange, formattedValue) || weekIsSelected(weekOrWeekRange, formattedDisableWeeks)
        );
      });

      if (isOverlapped) {
        notification.error({ message: language.weeks_are_already_in_used });
        updatedValues = value;
        tracker.current = 0;
      } else {
        updatedValues = [...value, newWeeks];
        newSelected = newWeeks;
        tracker.current = 0;
      }
    } else {
      tracker.current = weekValue;
      updatedValues = [...value, weekValue];
    }
    if (isBatchSelection) {
      setlocalFullFilledWeeks(
        uniq([
          ...localFullFilledWeeks,
          Array.isArray(newSelected) ? newSelected.sort().join(',') : newSelected.toString(),
        ]),
      );
    }
    onChange(allowMultiple ? compact([...updatedValues]) : compact([updatedValues[updatedValues.length - 1]]));
  };

  const onHover = (week: number) => {
    if (tracker.current && allowRange) {
      setHovered(week);
    }
  };

  const onKey = (e: KeyboardEvent) => {
    if (!allowRange) return;
    isShifted.current = e.shiftKey;
    if (isShifted.current) {
      setHovered(currentHovering.current?.dataset.week ? Number(currentHovering.current?.dataset.week) : 0);
    } else {
      setHovered(0);
    }
  };

  const weeks = useMemo(() => {
    const startOfYear = dayjs(year.toString(), 'YYYY');
    const _weeks: Dayjs[] = [];
    for (let i = 0; i < 54; i += 1) {
      if (startOfYear.add(i, 'week').endOf('week').year() === year) {
        _weeks.push(startOfYear.add(i, 'week'));
      }
    }

    return (
      <div data-testid="WEEK_SELECTOR__WEEKS" className="weeks-selector__weeks">
        {_weeks.map((week) => {
          const weekValue = toCenterOfWeek(week).valueOf();
          const foundValueRange = formattedValue.find((val) => Array.isArray(val) && isInWeekRange(weekValue, val));
          const foundDisabledRange = formattedDisableWeeks?.find(
            (val) => Array.isArray(val) && isInWeekRange(weekValue, val),
          );

          const foundRange = foundValueRange || foundDisabledRange;
          const isSelected = weekIsSelected(weekValue, formattedValue);

          const isHovering = tracker.current && hovered && isInWeekRange(weekValue, [tracker.current, hovered]);

          const isFirstInRange = Array.isArray(foundRange) && weekValue === foundRange[0];
          const isLastInRange = Array.isArray(foundRange) && weekValue === foundRange[foundRange.length - 1];
          const isFirstHovering = tracker.current && weekValue === Math.min(tracker.current, hovered);
          const isLastHovering = tracker.current && weekValue === Math.max(tracker.current, hovered);
          const isFirst = isFirstInRange || (isHovering && isFirstHovering);
          const isLast = isLastInRange || (isHovering && isLastHovering);
          const isInRange = !isFirst && !isLast && (isHovering || Array.isArray(foundRange));
          const isDisabled = disabledWeeks?.includes(weekValue) || !!foundDisabledRange;
          const isGhost = !isSelected && weekValue !== hovered && !isInRange && !foundDisabledRange && !isDisabled;
          const allFullfilledWeeks = [...fullfilledWeeks, ...localFullFilledWeeks];
          let isFullFill = false;
          if (foundValueRange || isSelected) {
            if (Array.isArray(foundValueRange)) {
              isFullFill = allFullfilledWeeks.includes(foundValueRange.join(','));
            } else if (isSelected) {
              isFullFill = allFullfilledWeeks.includes(weekValue.toString());
            }
          }

          return (
            <div
              key={weekValue}
              className={`weeks-selector__week ${isInRange ? 'is-in-range' : ''} ${isDisabled ? 'is-disabled' : ''}
              ${compact([
                isFirst && 'is-first-in-range',
                isLast && 'is-last-in-range',
                isFirstHovering && 'is-first-hovering',
                isLastHovering && 'is-last-hovering',
                isHovering && 'is-hovering',
                isFullFill && 'is-full-fill',
                !isGhost && 'is-selected',
              ]).join(' ')}`}
            >
              <Button
                data-week={weekValue}
                type="primary"
                ghost={isGhost}
                onClick={(e) => onSelect(e.shiftKey, week)}
                shape="round"
                onMouseEnter={(e) => {
                  if (isShifted.current) {
                    onHover(weekValue);
                  }
                }}
                onMouseLeave={() => setHovered(0)}
                onKeyDown={onKey}
                onKeyUp={onKey}
                disabled={isDisabled}
              >
                <span>
                  <span>{typeof labelRender === 'function' ? labelRender(weekValue) : week.format('W')}</span>
                </span>
              </Button>
            </div>
          );
        })}
      </div>
    );
  }, [year, value, tracker.current, hovered, currentHovering.current, disabledWeeks]);

  const onMouseMove = (e: React.MouseEvent) => {
    if (!weekPositions.current) return;
    const { clientX, clientY } = e;
    const hoveringIndex = weekPositions.current.findIndex(({ top, left, right, bottom }: TElementPosition) => {
      return left <= clientX && clientX <= right && top <= clientY && clientY <= bottom;
    });
    const weekButtons = document.querySelectorAll('.weeks-selector__weeks button');
    weekButtons.forEach((elm, elmIndex) => {
      if (weekButtons[hoveringIndex]) {
        if (hoveringIndex === elmIndex) {
          currentHovering.current = elm as HTMLButtonElement;
        }
      } else {
        currentHovering.current = null;
      }
    });
  };

  const weekPositions = useRef<null | TElementPosition[]>(null);

  const setupWeeksPosition = useCallback(() => {
    const weekButtons = document.querySelectorAll('.weeks-selector__weeks button');
    if (!weekButtons?.length) return;
    const positions: TElementPosition[] = [];
    for (let i = 0; i < weekButtons.length; i += 1) {
      const btn = weekButtons[i];
      const { top, left, width, height } = btn.getBoundingClientRect();
      positions.push({
        top,
        left,
        right: left + width,
        bottom: top + height,
      });
      weekPositions.current = positions;
    }
  }, []);
  useEffect(() => {
    // Timeout is just to make sure all elements are rendered
    setTimeout(() => {
      setupWeeksPosition();
    }, 0);
    window.addEventListener('resize', setupWeeksPosition);
    return () => {
      window.removeEventListener('resize', setupWeeksPosition);
    };
  }, []);

  return (
    <div
      data-testid="WEEK_SELECTOR"
      className={`weeks-selector ${allowRange ? 'weeks-selector--range-select' : ''}`}
      onMouseMove={onMouseMove}
    >
      <Flex data-testid="WEEK_SELECTOR__YEAR_SWITCHER" style={{ userSelect: 'none' }}>
        <CaretLeftFilled onClick={() => setYear(year - 1)} />
        <b className="te-ml-2 te-mr-2">{year}</b>
        <CaretRightFilled onClick={() => setYear(year + 1)} />
      </Flex>
      {weeks}
      {allowMultiple && (
        <div className="te-pt-3">
          <SelectedWeeksCounter weeks={value} />
        </div>
      )}
      {touched && changeNote && <div className="te-mt-2">{changeNote}</div>}
    </div>
  );
}
