/* Contains the "Changes have been made. Click the button to update." box and the logic to update the current view.
 */

import React, { useCallback, useContext, useEffect } from 'react';
import {
  activityGroupBySelector,
  checkIfChangedActivitiesAreOnCurrentPage,
  currentPageContainsDataToBeRefreshedSelector,
  ESocketUseCase,
  fetchActivities,
  fetchActivitiesSuccess,
  refreshedActivitiesOfCurrentPageSelector,
  removeRefreshedActivitiesOfCurrentPage,
  setCurrentPageContainsDataToBeRefreshed,
  refetchActivityById,
  refetchActivitySeriesById,
} from 'activities/pages/slices/activity.slice';
import intl from 'i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'antd';
import { AMSocketContext, TAMSocketEventMap } from 'services/am-socket.service';
import {
  ESocketEvent,
  TDefaultSocketPayload,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/WebSocket.type';
import { useAppFeatureFlags } from '../../hooks/useAppFeatureFlags';
import { Button } from '@timeedit/ui-components';
import { ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';

type TSocketUseCaseOverViewTable = {
  socketUseCase: ESocketUseCase.overviewTable;
};

type TSocketUseCaseSingleActivityDrawer = {
  socketUseCase: ESocketUseCase.singleActivityDrawer;
  activityId: string | undefined;
};

type TSocketUseCaseActivitySeriesDrawer = {
  socketUseCase: ESocketUseCase.activitySeriesDrawer;
  activitySeriesId: string | undefined;
};

type TSocketUseCaseActivityTrackDrawer = {
  socketUseCase: ESocketUseCase.activityTrackDrawer;
  activitySeriesId: string | undefined;
  trackId: number | undefined;
};

export type TRefreshCurrentViewBoxAndLogicProps = {
  useCase:
    | TSocketUseCaseOverViewTable
    | TSocketUseCaseSingleActivityDrawer
    | TSocketUseCaseActivitySeriesDrawer
    | TSocketUseCaseActivityTrackDrawer;
};

const language = intl.messages as Record<string, string>;

export default function RefreshCurrentViewBoxAndLogic({ useCase }: TRefreshCurrentViewBoxAndLogicProps) {
  const dispatch = useDispatch();
  const activityGroupBy = useSelector(activityGroupBySelector);

  const socketContext = useContext(AMSocketContext);
  const { showDmStatusFilter, examFlowV3 } = useAppFeatureFlags();
  const refreshedActivitiesOfCurrentPage = useSelector(refreshedActivitiesOfCurrentPageSelector);
  const currentPageContainsDataToBeRefreshed = useSelector(currentPageContainsDataToBeRefreshedSelector);

  const refetchCurrentPage = useCallback(() => {
    if (useCase.socketUseCase === ESocketUseCase.overviewTable) {
      if (refreshedActivitiesOfCurrentPage) {
        dispatch(fetchActivitiesSuccess(refreshedActivitiesOfCurrentPage));
        dispatch(removeRefreshedActivitiesOfCurrentPage());
      } else {
        dispatch(fetchActivities(activityGroupBy, { showDmStatusFilter, examFlowV3 }));
      }
    }
    if (useCase.socketUseCase === ESocketUseCase.singleActivityDrawer) {
      dispatch(refetchActivityById([useCase.activityId!]));
    }
    if (
      useCase.socketUseCase === ESocketUseCase.activitySeriesDrawer ||
      useCase.socketUseCase === ESocketUseCase.activityTrackDrawer
    ) {
      dispatch(refetchActivitySeriesById([useCase.activitySeriesId!]));
    }

    dispatch(setCurrentPageContainsDataToBeRefreshed({ socketUseCase: useCase.socketUseCase, newValue: false }));
  }, [activityGroupBy, dispatch, examFlowV3, refreshedActivitiesOfCurrentPage, showDmStatusFilter, useCase]);

  const returnIdsInDrawer = useCallback(() => {
    return {
      activityId: 'activityId' in useCase ? useCase.activityId : undefined,
      activitySeriesId: 'activitySeriesId' in useCase ? useCase.activitySeriesId : undefined,
      trackId: 'trackId' in useCase ? useCase.trackId : undefined,
    };
  }, [useCase]);

  const socketName = useCase.socketUseCase;
  const socketEventMap: Partial<TAMSocketEventMap> = {
    [ESocketEvent.ACTIVITY_GENERATION_UPDATE]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_GENERATION_UPDATE', payload);
      },
    },
    [ESocketEvent.ACTIVITY_GENERATION_STATUS]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_GENERATION_STATUS', payload);
      },
    },
    [ESocketEvent.ACTIVITY_BATCH_OPERATION_UPDATE]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_BATCH_OPERATION_UPDATE', payload);
        dispatch(
          checkIfChangedActivitiesAreOnCurrentPage(
            payload,
            { showDmStatusFilter, examFlowV3 },
            useCase.socketUseCase,
            returnIdsInDrawer(),
          ),
        );
      },
    },
    [ESocketEvent.ACTIVITY_SERIES_TRACKS_OR_WEEKS_CHANGED]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_BATCH_OPERATION_UPDATE', payload);
        dispatch(
          checkIfChangedActivitiesAreOnCurrentPage(
            payload,
            { showDmStatusFilter, examFlowV3 },
            useCase.socketUseCase,
            returnIdsInDrawer(),
          ),
        );
      },
    },
    [ESocketEvent.ACTIVITY_SERIES_TRACKS_ADDED]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_BATCH_OPERATION_UPDATE', payload);
        dispatch(
          checkIfChangedActivitiesAreOnCurrentPage(
            payload,
            { showDmStatusFilter, examFlowV3 },
            useCase.socketUseCase,
            returnIdsInDrawer(),
          ),
        );
      },
    },

    [ESocketEvent.FILTER_LOOKUP_MAP_UPDATE]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('FILTER_LOOKUP_MAP_UPDATE', payload);
      },
    },
  };
  useEffect(() => {
    console.log('socketEventMap', socketEventMap, typeof socketContext.setEventMap);
    socketContext.setEventMap(socketEventMap);
    return () => {
      console.log('removeEventMap', socketEventMap);
      socketContext.removeEventMap(socketEventMap);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {currentPageContainsDataToBeRefreshed[useCase.socketUseCase] && (
        <div className="ant-row te-flex" style={{ margin: '10px' }}>
          <Alert
            style={{ width: '100%' }}
            showIcon
            icon={<ExclamationCircleOutlined />}
            type="warning"
            key="currentPageContainsDataToBeRefreshedInfo"
            message={
              <div className="te-flex">
                <div className="ant-col">{language.changes_made_to_table}</div>
                <div className="ant-col" style={{ marginLeft: 'auto' }}>
                  <Button onClick={refetchCurrentPage} size="small" icon={<SyncOutlined />}>
                    {language.update}
                  </Button>
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}
