/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { TFormInstance } from '@timeedit/types/lib/types';
import { TActivityResultsInResponse } from 'activities/pages/types/activity.type';
import { getActivityFormInstancesStatus, getFormNameByIds } from 'activities/services/activities.service';
import { orderBy, uniq } from 'lodash';
import TEObjectManager from 'activities/services/TEObjects.service';
import intl, { getInlineString } from 'i18n/intl';
import { useSelector } from 'react-redux';
import { organizationSelector } from '../../../slices/organization.slice';
import { Button, Spin } from 'antd';
import DivWithHtml from 'utils/security';
import SortColumnsModal from '@timeedit/ui-components/lib/src/components/EditableTable/SortColumnsDialog';
import { ColumnConfig } from '@timeedit/ui-components';
import localStorageHelper from 'utils/localStorage.helper';

const language = intl.messages as Record<string, string>;

export const withFormInstanceStatus = ({ activities }: { activities: TActivityResultsInResponse[] }) => {
  const [formInstanceStatus, setFormInstanceStatus] = useState<Record<string, TFormInstance['status']>>({});

  useEffect(() => {
    const doGetInstanceStatus = async () => {
      const formInstanceIds = uniq(
        activities
          .filter(({ formInstanceId }) => formInstanceId)
          .map(({ formInstanceId }) => formInstanceId.toString()),
      );
      if (!formInstanceIds.length) return;
      const response = await getActivityFormInstancesStatus(formInstanceIds);
      setFormInstanceStatus(response.data?.formInstances ?? {});
    };
    doGetInstanceStatus();
  }, [activities]);

  return { formInstanceStatus };
};

export const useSendToReviewPopover = ({
  selectedRowKeys,
  activitySeries,
}: {
  selectedRowKeys?: string[];
  activitySeries: TActivityResultsInResponse[];
}) => {
  const [open, setOpen] = useState(false);
  const [loadFormName, setLoadFormName] = useState(false);
  const [formNames, setFormNames] = useState<string[]>([]);
  const organization = useSelector(organizationSelector);
  useEffect(() => {
    if (open) {
      const sourceIds = uniq(activitySeries.map(({ sourceId }) => sourceId));
      try {
        setLoadFormName(true);
        getFormNameByIds(organization?.id, sourceIds).then((response) => {
          setFormNames(uniq(response?.map((d) => d.activityCreateTemplateName)) ?? []);
        });
      } finally {
        setLoadFormName(false);
      }
    } else {
      setFormNames([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const title = useMemo(() => {
    if (!open) return '';
    if (loadFormName) {
      return <Spin />;
    }
    const selectedSeries = activitySeries.filter((item) =>
      selectedRowKeys?.includes(
        'activitySeriesId' in item ? item.activitySeriesId : (item.activitySeriesGroup?.activitySeriesId ?? ''),
      ),
    );
    const formNameString = formNames.join(', ');
    const primaryObject = selectedSeries.length
      ? TEObjectManager.getObjectTypeLabelByObject(
          'primaryObject' in selectedSeries[0]
            ? selectedSeries[0].primaryObject
            : selectedSeries[0].metadata?.primaryObject,
        )
      : 'primary objects';
    return (
      <div>
        <DivWithHtml
          htmlInput={getInlineString('activities.overview.table.review_confirmation', primaryObject)}
          allowedHTMLTags={['br', 'b']}
        />
        <DivWithHtml
          htmlInput={getInlineString('activities.overview.table.form_name_information', formNameString)}
          allowedHTMLTags={['b']}
        />
      </div>
    );
  }, [open, selectedRowKeys, activitySeries, loadFormName, formNames]);

  return {
    open,
    setOpen,
    title,
  };
};

type TColumn = {
  title: string | ReactNode;
  key: string;
};
export const useColumnsManager = ({ tableId, columns }: { tableId: string; columns: TColumn[] }) => {
  const [open, setOpen] = useState(false);
  const [columnConfigs, setColumnConfigs] = useState<ColumnConfig[]>([]);

  useEffect(() => {
    const stored = localStorageHelper.columnsVisible[tableId];
    const filteredColumns = orderBy(
      columns
        .filter((col) => col.key)
        .map((col) => ({
          ...col,
          label: col.title,
          hidden: stored && !stored?.includes(col.key),
        })),
      [
        (col) => {
          const idx = stored?.findIndex((key) => key === col.key);
          if (idx === -1) return columns.length + 1;
          return idx;
        },
      ],
      ['asc'],
    );
    setColumnConfigs(
      // @ts-ignore Temporary ignore ts check for label, it does not allow React Element for now
      filteredColumns,
    );
  }, [columns.length]);

  const onConfigsChange = (configs: ColumnConfig[]) => {
    setColumnConfigs(configs);
    localStorageHelper.updateColumnsVisible(
      tableId,
      configs.filter((col) => !col?.hidden).map((col) => col.key),
    );
  };

  return {
    modal: (
      <SortColumnsModal
        key={tableId}
        onConfirm={(configs) => {
          onConfigsChange(configs);
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
        open={open}
        columnConfigs={columnConfigs}
      />
    ),
    modalTrigger: (
      <Button onClick={() => setOpen(true)} size="small">
        {language.edit_columns}
      </Button>
    ),
    visibleColumns: columnConfigs.filter((col) => !col.hidden).map((col) => col.key),
  };
};
