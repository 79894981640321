import React, { ReactNode } from 'react';

import { TActivityElementValue } from 'activities/pages/types/activity.type';
import { Typography } from 'antd';
import { toActivityValueDisplay } from './ActivitiesTable.utils';
import ActivityCategoriesValue from './ActivityCategoriesValue';
import { isEmpty } from 'lodash';

export default function ActivityValueCell({
  activityValue,
  children,
}: {
  activityValue: TActivityElementValue;
  children?: ReactNode;
}) {
  if (isEmpty(activityValue)) return null;

  if (activityValue.categories) {
    return <ActivityCategoriesValue activityValueCategories={activityValue.categories} />;
  }

  return (
    <Typography.Text ellipsis style={{ color: 'inherit' }}>
      {toActivityValueDisplay(activityValue)}
      {children || ''}
    </Typography.Text>
  );
}
