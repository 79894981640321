import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons';
import React from 'react';

export default function ExpandIcon({
  expanded,
  onExpand,
  record,
}: {
  expanded: boolean;
  onExpand: (record: any, event: React.MouseEvent<HTMLElement>) => void;
  record: any;
}) {
  if (!record?.children) return <span />;
  return expanded ? (
    <span
      className={'expand-icon'}
      onClick={(e) => {
        e.stopPropagation();
        return onExpand(record, e);
      }}
    >
      <CaretDownFilled />
    </span>
  ) : (
    <span
      className={'expand-icon'}
      onClick={(e) => {
        e.stopPropagation();
        return onExpand(record, e);
      }}
    >
      <CaretRightFilled />
    </span>
  );
}
