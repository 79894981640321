import React, { useEffect, useMemo } from 'react';
import {
  activityGroupBySelector,
  changeActivityGroupBy,
  changeSelectedActivityIds,
  ESocketUseCase,
  resetOverviewTrigger,
  TActivityGroupBy,
} from 'activities/pages/slices/activity.slice';
import intl from 'i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import { Segmented, Tabs } from 'antd';
import ActivitiesTable from './ActivitiesTable2';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import RefreshCurrentViewBoxAndLogic from '../Socket/RefreshCurrentViewBoxAndLogic';

const language = intl.messages as Record<string, string>;

export default function AllActivitiesTable() {
  const dispatch = useDispatch();
  const activityGroupBy = useSelector(activityGroupBySelector);

  const onTabChange = (value: string) => {
    dispatch(changeActivityGroupBy(value as EActivityGroupings.ACTIVITY_SERIES | EActivityGroupings.FLAT));
    dispatch(changeSelectedActivityIds([]));
  };
  const tabs = useMemo(() => {
    return [
      {
        key: 'ACTIVITY_SERIES',
        label: 'Activity series',
        children: <ActivitiesTable type="ACTIVITY_SERIES" />,
      },
      {
        key: 'FLAT',
        label: 'All activities',
        children: <ActivitiesTable type="FLAT" />,
      },
    ];
  }, []);

  const onDrawerClose = () => {
    dispatch(resetOverviewTrigger());
  };
  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onDrawerClose);
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_DRAWER, onDrawerClose);
    document.addEventListener(ActivityEvents.CLOSE_ACTIVITY_SERIES_DRAWER, onDrawerClose);
    document.addEventListener(ActivityEvents.CLOSE_ACTIVITY_DRAWER, onDrawerClose);

    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onDrawerClose);
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_DRAWER, onDrawerClose);
      document.removeEventListener(ActivityEvents.CLOSE_ACTIVITY_SERIES_DRAWER, onDrawerClose);
      document.removeEventListener(ActivityEvents.CLOSE_ACTIVITY_DRAWER, onDrawerClose);
    };
  }, []);

  return (
    <>
      <div className="ant-row te-flex" style={{ marginBottom: '10px' }}>
        <div className="ant-col" style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
          {language['general.group_by'] as string}
        </div>
        <div className="ant-col">
          <Segmented<TActivityGroupBy>
            options={[
              { label: language['activites.activity_series'] as string, value: EActivityGroupings.ACTIVITY_SERIES },
              { label: language['activites.all_activities'] as string, value: EActivityGroupings.FLAT },
            ]}
            onChange={onTabChange}
            value={activityGroupBy}
          />
        </div>
      </div>

      <RefreshCurrentViewBoxAndLogic useCase={{ socketUseCase: ESocketUseCase.overviewTable }} />

      <Tabs
        defaultActiveKey="1"
        items={tabs}
        activeKey={activityGroupBy}
        onChange={onTabChange}
        renderTabBar={() => <div />}
        destroyInactiveTabPane
      />
    </>
  );
}
