/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IAuthState, IState } from '../types/state.interface';
import TagsManager from '../activities/services/Tags.service';
import localStorageHelper from 'utils/localStorage.helper';

const initialState: IAuthState = {
  loading: true,
  hasErrors: false,
  user: null,
  websocketTrigger: 0,
  tokenData: undefined,
};
const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoginSuccess: (state, { payload: user }) => {
      if (!user) {
        /**
         * If we don't have a user, this should be considered an error
         * and we'll consequently set the redux state to mimic this
         */
        state.hasErrors = true;
        state.user = null;
      } else {
        state.hasErrors = false;
        state.user = user;
        TagsManager.init(user.organizationId);
        localStorageHelper.init(user.organizationId);
      }
      state.loading = false;
    },
    onLogout: (state) => {
      state.hasErrors = false;
      state.loading = false;
      state.user = null;
    },
    onRetryWebsocketConnection: (state) => {
      state.websocketTrigger = 1 + (state.websocketTrigger || 0);
    },
  },
});

export default slice.reducer;

// Selectors
export const userSelector = (state: IState) => state.auth.user;
export const websocketTriggerSelector = (state: IState) => state.auth.websocketTrigger;
export const organizationIdSelector = (state: IState) => state.auth.user?.organizationId;

// Actions
export const { onLoginSuccess, onLogout, onRetryWebsocketConnection } = slice.actions;
