import GenerateStudentSet from '../study-combinations/pages/Views/GenerateStudentSet';
import ReviewPathwayView from '../study-combinations/pages/Views/ReviewPathwayView';
import SettingsPage from '../study-combinations/pages/Views/Settings';
import intl from '../i18n/intl';
import { ITEToken, TNavigation } from '@timeedit/types/lib/types';
import { getVisibility } from '../utils/authentication';

import { ActivitiesOverviewPage } from '../activities/pages/ActivitiesOverviewPage';
import { ActivityCreateTemplateOverviewPage } from '../activities/components/Table/ActivityCreateTemplateOverviewPage';
import { trimCloudPrefixFromCustomerSignature } from '../utils/auth.helper';

const language = intl.messages;

type FeatureFlagsInRouter = { dmActivityCreateTemplateManagement: boolean };

export const componentMap = {
  // Study-Combinations
  GenerateStudentSet,
  ReviewPathwayView,
  SettingsPage,

  // Activities
  ActivitiesOverviewPage,
  ActivityCreateTemplateOverviewPage,
};

const getActivitiesNavigation = (authUser: ITEToken, { dmActivityCreateTemplateManagement }: FeatureFlagsInRouter) => {
  const customerSignature = trimCloudPrefixFromCustomerSignature(authUser?.customerSignature) ?? '';
  const showActivitiesNavigation = getVisibility([], { scopes: ['TE_ACTIVITIES::user'] }, authUser);
  if (!showActivitiesNavigation) {
    return [];
  }

  return [
    {
      path: `/${customerSignature}/activities`,
      label: 'Activities',
      visible: true,
      redirectTo: `/${customerSignature}/activities/overview`,
      subItems: [
        {
          label: language['activities.overview.title'] as string,
          path: `/${customerSignature}/activities/overview`,
          visible: true,
          component: 'ActivitiesOverviewPage',
        },
        {
          label: language['activities.create_template.overview.title'] as string,
          path: `/${customerSignature}/activities/import-template`,
          visible: dmActivityCreateTemplateManagement,
          component: 'ActivityCreateTemplateOverviewPage',
        },
      ],
    },
  ];
};

const getStudyCombinationsNavigation = (authUser: ITEToken) => {
  const customerSignature = trimCloudPrefixFromCustomerSignature(authUser?.customerSignature) ?? '';
  const showStudyCombinationsNavigation = getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser);
  if (!showStudyCombinationsNavigation) {
    return [];
  }
  return [
    {
      path: `/${customerSignature}/study-combinations`,
      label: language.study_combinations as string,
      visible: true,
      redirectTo: `/${customerSignature}/study-combinations/review`,
      subItems: [
        {
          label: language['review_study_combination.title'] as string,
          path: `/${customerSignature}/study-combinations/review`,
          visible: true,
          component: 'ReviewPathwayView',
        },
        {
          label: language['generate_student_sets.title'] as string,
          path: `/${customerSignature}/study-combinations/generate`,
          visible: true,
          component: 'GenerateStudentSet',
        },
      ],
    },
  ];
};

export const getNavigation = (authUser: ITEToken, featureFlags: FeatureFlagsInRouter): TNavigation[] => {
  return [...getStudyCombinationsNavigation(authUser), ...getActivitiesNavigation(authUser, featureFlags)];
};
